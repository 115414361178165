<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="verifikasiAset"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mt-4 px-8 pt-4 pb-10"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup.enter.native="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.price_year="{ item }">
          <div
            style="
            display: flex;
            justify-content: space-between;
          "
          >
            <span>
              Rp
            </span>

            <span>
              {{ (item.price_year || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.price_month="{ item }">
          <div
            style="
            display: flex;
            justify-content: space-between;
          "
          >
            <span>
              Rp
            </span>

            <span>
              {{ (item.price_month || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.price_day="{ item }">
          <div
            style="
            display: flex;
            justify-content: space-between;
          "
          >
            <span>
              Rp
            </span>

            <span>
              {{ (item.price_day || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.action_btn_hide === false"
            width="110px"
            height="32px"
            style="
            background: #7A1878;
          "
            @click="toDetail(item)"
          >
            <span
              class="text-capitalize"
              style="
              font-weight: bold;
              font-size: 12px;
              color: #FFFFFF;
            "
            >
              Verifikasi
            </span>
          </v-btn>
          <small v-if="item.action_btn_hide === true">
            {{ item.action_btn_hide_msg }}
          </small>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              :total-visible="5"
              @input="onChangePage"
            />
            <!-- <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span> -->
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </div>
          <!-- <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row> -->
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG DETAIL VENDOR-->
    <v-dialog
      v-model="dialogVendorDetail"
      max-width="600"
    >
      <v-card class="px-4 py-4">
        <v-row>
          <v-col class="text-center">
            <span style="font-weight: bold">
              Informasi Perusahaan Vendor
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogVendorDetail = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nama Mitra
          </v-col>

          <v-col
            cols="8"
            sm="8"
          >
            {{ selectedVendor.prt_comp_name || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nama Vendor
          </v-col>

          <v-col
            cols="8"
            sm="8"
          >
            {{ selectedVendor.company || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nama Pimpinan
          </v-col>

          <v-col
            cols="8"
            sm="8"
          >
            {{ selectedVendor.name || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Telepon
          </v-col>

          <v-col
            cols="8"
            sm="8"
          >
            {{ selectedVendor.phone || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Email
          </v-col>

          <v-col
            cols="8"
            sm="8"
          >
            {{ selectedVendor.email || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Alamat
          </v-col>

          <v-col
            cols="8"
            sm="8"
          >
            {{ selectedVendor.address || '-' }}
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      tab: null,
      tabDialog: null,
      dialogVendorDetail: false,

      // Verifikasi Aset:
      search: '',
      headers: [
        { text: 'Nama Mitra', align: 'start', value: 'prt_name_legal' },
        { text: 'Kode Aset', value: 'code', sortable: false },
        { text: 'Nama Media', value: 'name' },
        { text: 'Lokasi', value: 'add_kot' },
        { text: 'Tanggal Pengajuan', value: 'tanggal_pengajuan' },
        { text: '', value: 'actions', sortable: false },
      ],
      verifikasiAset: [],

      isAllowDetail: false,
      isAllowVerification: false,

      selectedVendor: {
        prt_comp_name: '',
        company: '',
        name: '',
        phone: '',
        email: '',
        address: '',
      },

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/asset/verification', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.verifikasiAset = res.data.data.list

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (item) {
        this.$router.push({ name: 'VerifikasiAsetDetail', params: { id: item.hash } })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

#action-container {
  background: #FFFFFF;
}

/* #action-container:hover ~ #action-icon {
  background: #7A1878;
  color: #FFFFFF;
} */

#action-icon {
  color: #7A1878;
}

/* #action-icon:hover {
  color: #FFFFFF;
} */

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
